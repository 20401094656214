import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import {  Carousel, Modal } from 'react-bootstrap';
import axios from 'axios';

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)', // Semi-transparent background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, // Ensures the modal is above other content
  },
  modal: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '5px',
    position: 'relative',
    maxWidth: '600px',
    width: '100%',
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  closeButton: {
    cursor: 'pointer',
    border: 'none',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    margin: '0',
    background: 'transparent',
  },
};
const NodalAgencyPending = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [showCarousel, setShowCarousel] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage('');
  };

  useEffect(() => {
       

    const fetchUser = async () => {
      try {
        const response = await axios.get(`https://ooh.warburttons.com/api/project/${id}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setUser(response.data.data);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUser();
  }, [id]);

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setShowCarousel(true);
  };
  const baseUrl = 'https://ooh.warburttons.com/';

  const handleCloseCarousel = () => setShowCarousel(false);
  // Export Table Data to Excel
  const exportToExcel = () => {
    const tableData = [
      ['Zone', user.zone],
      ['State', user.state],
      ['City', user.city],
      ['Code', user.code],

      ['Name', user.project],
      ['Lat', user.lat],
      ['Long', user.long],
      ['Length', user.length],
      ['Width', user.width],
      ['Total', user.total],
      ['Vendor Name', user.vendor_name],
      ['Phone Number', user.mobile],
      ['Area', user.status],
      ['District', user.vendor_status],
      ['ASM Name', user.asm_name],
      ['ZO Name', user.zo_name],
      ['Recce Person Name', user.racce_person_name],
      [
        'Recce Person Image',
        {
          f: `HYPERLINK("${baseUrl}${user.racce_person_image}", "${baseUrl}${user.racce_person_image}")`,
        },
      ],

      ['Image1', { f: `HYPERLINK("${baseUrl}${user.image1}", "${baseUrl}${user.image1}")` }],
      ['Image2', { f: `HYPERLINK("${baseUrl}${user.image2}", "${baseUrl}${user.image2}")` }],
      ['Image3', { f: `HYPERLINK("${baseUrl}${user.image3}", "${baseUrl}${user.image3}")` }],
      ['Image4', { f: `HYPERLINK("${baseUrl}${user.image4}", "${baseUrl}${user.image4}")` }],
    ];

    const ws = XLSX.utils.aoa_to_sheet(tableData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Project Details');
    XLSX.writeFile(wb, 'Project_Details.xlsx');
  };
//   console.log(user, 'lll');
  // Print the Table Data
  const printTable = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
      <head>
        <title>Print Table</title>
        <style>
          @page {
            margin: 0;
          }
          body {
            margin: 1cm; 
          }
            h4{
            margin-bottom:5px;
            margin-top:5px;
            }
            body::before {
            content: 'Dalmia Cement';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            font-size: 80px;
            color: rgba(0, 0, 0, 0.1);
            z-index: -1;
            white-space: nowrap;
          }
          table {
            width: 100%; 
            border-collapse: collapse;
          }
          th, td {
            border: 1px solid #ddd; 
            padding: 8px;
          }
          th {
            background-color: #f2f2f2;
          }
          h1 {
            text-align: center;
          }
                .no-border {
            border: none;
          }
            .h4-head-p{
            display:flex;}
        </style>
      </head>
        <body>
          <h3 className="h4-head-p" style="margin-bottom:30px">Site Name : ${
            user.site_name
          } &nbsp; &nbsp; Code : ${user.code}</h3>
          
          <table>
            <tbody>
              <tr>
                    <td><strong>Zone</strong></td>
                <td>${user.zone}</td>
               
                <td><strong>City</strong></td>
                <td>${user.city}</td>
                <td><strong>Vendor Name</strong></td>
                <td>${user.vendor_name}</td>
              </tr>
              <tr>
             
              
                <td><strong>State</strong></td>
                <td>${user.state}</td>
                  <td><strong>Start Date</strong></td>
                <td>${user.start_date}</td>
                    <td><strong>End Date</strong></td>
                <td>${user.end_date}</td>
                 
              </tr>
              <tr>
                <td><strong>Height</strong></td>
                <td>${user.length}</td>
                 <td><strong>Width</strong></td>
                <td>${user.width}</td>
                <td><strong>Total</strong></td>
                <td>${user.total}</td>
               
              </tr>
              <tr>
                <td><strong>Lat</strong></td>
                <td>${user.lat}</td>
                <td><strong>Long</strong></td>
                <td>${user.long}</td>
                
              <td><strong>Vendor Status</strong></td>
                <td>${user.vendor_status} ${(() => {
      const dateString = user.vendor_approval_date;
      if (!dateString) return ''; // Return an empty string if no date is provided

      const cleanDate = dateString.split(' ')[0]; // Extract only the date part (before space)

      // Handle DD/MM/YYYY format (no changes needed)
      if (/\d{2}\/\d{2}\/\d{4}/.test(cleanDate)) {
        return cleanDate; // Return DD/MM/YYYY as-is
      }

      // Handle DD-MM-YY format (no changes needed)
      if (/\d{2}-\d{2}-\d{2}/.test(cleanDate)) {
        return cleanDate; // Return DD-MM-YY as-is
      }

      // Handle YYYY-MM-DD format or valid ISO date
      const formattedDate = new Date(cleanDate);
      if (formattedDate.toString() !== 'Invalid Date') {
        return formattedDate.toLocaleDateString('en-IN', {
          timeZone: 'Asia/Kolkata',
        });
      }

      return ''; // Return an empty string for unrecognized formats
    })()}</td>
              </tr>

                <tr>
                <td><strong>Nodal Agency Status</strong></td>
                <td>${user.nodle_agency_status} ${(() => {
      const dateString = user.nodle_agency_approval_date;
      if (!dateString) return ''; // Return an empty string if no date is provided

      const cleanDate = dateString.split(' ')[0]; // Extract only the date part (before space)

      // Handle DD/MM/YYYY format (no changes needed)
      if (/\d{2}\/\d{2}\/\d{4}/.test(cleanDate)) {
        return cleanDate; // Return DD/MM/YYYY as-is
      }

      // Handle DD-MM-YY format (no changes needed)
      if (/\d{2}-\d{2}-\d{2}/.test(cleanDate)) {
        return cleanDate; // Return DD-MM-YY as-is
      }

      // Handle YYYY-MM-DD format or valid ISO date
      const formattedDate = new Date(cleanDate);
      if (formattedDate.toString() !== 'Invalid Date') {
        return formattedDate.toLocaleDateString('en-IN', {
          timeZone: 'Asia/Kolkata',
        });
      }

      return ''; // Return an empty string for unrecognized formats
    })()}</td>
                <td><strong>State Head Status</strong></td>
                <td>${user.asm_status} ${(() => {
      const dateString = user.asm_approval_date;
      if (!dateString) return ''; // Return an empty string if no date is provided

      const cleanDate = dateString.split(' ')[0]; // Extract only the date part (before space)

      // Handle DD/MM/YYYY format (no changes needed)
      if (/\d{2}\/\d{2}\/\d{4}/.test(cleanDate)) {
        return cleanDate; // Return DD/MM/YYYY as-is
      }

      // Handle DD-MM-YY format (no changes needed)
      if (/\d{2}-\d{2}-\d{2}/.test(cleanDate)) {
        return cleanDate; // Return DD-MM-YY as-is
      }

      // Handle YYYY-MM-DD format or valid ISO date
      const formattedDate = new Date(cleanDate);
      if (formattedDate.toString() !== 'Invalid Date') {
        return formattedDate.toLocaleDateString('en-IN', {
          timeZone: 'Asia/Kolkata',
        });
      }

      return ''; // Return an empty string for unrecognized formats
    })()}</td>
                
              <td><strong>RMH Status</strong></td>
                <td>${user.client_status} ${(() => {
      const dateString = user.rhm_approval_date;
      if (!dateString) return ''; // Return an empty string if no date is provided

      const cleanDate = dateString.split(' ')[0]; // Extract only the date part (before space)

      // Handle DD/MM/YYYY format (no changes needed)
      if (/\d{2}\/\d{2}\/\d{4}/.test(cleanDate)) {
        return cleanDate; // Return DD/MM/YYYY as-is
      }

      // Handle DD-MM-YY format (no changes needed)
      if (/\d{2}-\d{2}-\d{2}/.test(cleanDate)) {
        return cleanDate; // Return DD-MM-YY as-is
      }

      // Handle YYYY-MM-DD format or valid ISO date
      const formattedDate = new Date(cleanDate);
      if (formattedDate.toString() !== 'Invalid Date') {
        return formattedDate.toLocaleDateString('en-IN', {
          timeZone: 'Asia/Kolkata',
        });
      }

      return ''; // Return an empty string for unrecognized formats
    })()}</td>
              </tr>
            
              <tr>
                <td width="200">
                  Recce Person Name 
                </td>
                <td>: ${user.racce_person_name}</td>

                <td width="200">
                  Recce Person Image  : 
                </td>
                <td>
                   <img src="https://ooh.warburttons.com/${
                     user.racce_person_image
                   }" alt="" width="60" height="60" />
                </td>
              </tr>

              <tr>
                <td class="no-border">
                  <img src="https://ooh.warburttons.com/${
                    user.image
                  }" alt="" width="60" height="60" />
                </td>
                <td class="no-border">
                  <img src="https://ooh.warburttons.com/${
                    user.image1
                  }" alt="" width="60" height="60" />
                </td>
             
                <td class="no-border">
                  <img src="https://ooh.warburttons.com/${
                    user.image2
                  }" alt="" width="60" height="60" />
                </td>
                  <td class="no-border">
                  <img src="https://ooh.warburttons.com/${
                    user.image3
                  }" alt="" width="60" height="60" />
                </td>
                  <td class="no-border">
                  <img src="https://ooh.warburttons.com/${
                    user.image4
                  }" alt="" width="60" height="60" />
                </td>

                
              </tr>
              <tr> <td >Image Updated Date :</td>
                  <td >
                 
                     ${(() => {
                       const dateString = user.image_last_updated_date;
                       if (!dateString) return ''; // Return an empty string if no date is provided

                       const cleanDate = dateString.split(' ')[0]; // Extract only the date part (before space)

                       // Handle DD/MM/YYYY format (no changes needed)
                       if (/\d{2}\/\d{2}\/\d{4}/.test(cleanDate)) {
                         return cleanDate; // Return DD/MM/YYYY as-is
                       }

                       // Handle DD-MM-YY format (no changes needed)
                       if (/\d{2}-\d{2}-\d{2}/.test(cleanDate)) {
                         return cleanDate; // Return DD-MM-YY as-is
                       }

                       // Handle YYYY-MM-DD format or valid ISO date
                       const formattedDate = new Date(cleanDate);
                       if (formattedDate.toString() !== 'Invalid Date') {
                         return formattedDate.toLocaleDateString('en-IN', {
                           timeZone: 'Asia/Kolkata',
                         });
                       }

                       return ''; // Return an empty string for unrecognized formats
                     })()}
                  </td></tr>

                    <tr>
          </tr>
          <tr>
  <td colspan="6" style="border:none; padding-top:20px; margin-bottom:0px;">
    <h2 style="border:none; padding-top:20px; margin-bottom:0px;">History</h2>
  </td>
</tr>
          ${user.histories
            .map(
              (item) => `
                 <body>
          <table>
          <h3>  Image Updated Date :
                 
                  ${(() => {
                    const dateString = item.image_last_updated_date;
                    if (!dateString) return ''; // Return an empty string if no date is provided

                    const cleanDate = dateString.split(' ')[0]; // Extract only the date part (before space)

                    // Handle DD/MM/YYYY format (no changes needed)
                    if (/\d{2}\/\d{2}\/\d{4}/.test(cleanDate)) {
                      return cleanDate; // Return DD/MM/YYYY as-is
                    }

                    // Handle DD-MM-YY format (no changes needed)
                    if (/\d{2}-\d{2}-\d{2}/.test(cleanDate)) {
                      return cleanDate; // Return DD-MM-YY as-is
                    }

                    // Handle YYYY-MM-DD format or valid ISO date
                    const formattedDate = new Date(cleanDate);
                    if (formattedDate.toString() !== 'Invalid Date') {
                      return formattedDate.toLocaleDateString('en-IN', {
                        timeZone: 'Asia/Kolkata',
                      });
                    }

                    return ''; // Return an empty string for unrecognized formats
                  })()}
                    
                  </h3>

            <tbody>
              <tr>
                <td><strong>Lat</strong></td>
                <td>${item.lat}</td>
                <td><strong>Long</strong></td>
                <td>${item.long}</td>
                 <td><strong>Nodal Agency Status</strong></td>
                <td>${item.nodle_agency_status} ${(() => {
                const dateString = user.nodle_agency_approval_date;
                if (!dateString) return ''; // Return an empty string if no date is provided

                const cleanDate = dateString.split(' ')[0]; // Extract only the date part (before space)

                // Handle DD/MM/YYYY format (no changes needed)
                if (/\d{2}\/\d{2}\/\d{4}/.test(cleanDate)) {
                  return cleanDate; // Return DD/MM/YYYY as-is
                }

                // Handle DD-MM-YY format (no changes needed)
                if (/\d{2}-\d{2}-\d{2}/.test(cleanDate)) {
                  return cleanDate; // Return DD-MM-YY as-is
                }

                // Handle YYYY-MM-DD format or valid ISO date
                const formattedDate = new Date(cleanDate);
                if (formattedDate.toString() !== 'Invalid Date') {
                  return formattedDate.toLocaleDateString('en-IN', {
                    timeZone: 'Asia/Kolkata',
                  });
                }

                return ''; // Return an empty string for unrecognized formats
              })()}</td>
             
              </tr>
            <tr>
              <td><strong>Vendor Status</strong></td>
                <td>${item.vendor_status} ${(() => {
                const dateString = user.vendor_approval_date;
                if (!dateString) return ''; // Return an empty string if no date is provided

                const cleanDate = dateString.split(' ')[0]; // Extract only the date part (before space)

                // Handle DD/MM/YYYY format (no changes needed)
                if (/\d{2}\/\d{2}\/\d{4}/.test(cleanDate)) {
                  return cleanDate; // Return DD/MM/YYYY as-is
                }

                // Handle DD-MM-YY format (no changes needed)
                if (/\d{2}-\d{2}-\d{2}/.test(cleanDate)) {
                  return cleanDate; // Return DD-MM-YY as-is
                }

                // Handle YYYY-MM-DD format or valid ISO date
                const formattedDate = new Date(cleanDate);
                if (formattedDate.toString() !== 'Invalid Date') {
                  return formattedDate.toLocaleDateString('en-IN', {
                    timeZone: 'Asia/Kolkata',
                  });
                }

                return ''; // Return an empty string for unrecognized formats
              })()}</td>
                  <td><strong>State Head Status</strong></td>
                <td>${item.asm_status} ${(() => {
                const dateString = user.asm_approval_date;
                if (!dateString) return ''; // Return an empty string if no date is provided

                const cleanDate = dateString.split(' ')[0]; // Extract only the date part (before space)

                // Handle DD/MM/YYYY format (no changes needed)
                if (/\d{2}\/\d{2}\/\d{4}/.test(cleanDate)) {
                  return cleanDate; // Return DD/MM/YYYY as-is
                }

                // Handle DD-MM-YY format (no changes needed)
                if (/\d{2}-\d{2}-\d{2}/.test(cleanDate)) {
                  return cleanDate; // Return DD-MM-YY as-is
                }

                // Handle YYYY-MM-DD format or valid ISO date
                const formattedDate = new Date(cleanDate);
                if (formattedDate.toString() !== 'Invalid Date') {
                  return formattedDate.toLocaleDateString('en-IN', {
                    timeZone: 'Asia/Kolkata',
                  });
                }

                return ''; // Return an empty string for unrecognized formats
              })()}</td>
                  <td><strong>RMH Status</strong></td>
                <td>${item.client_status} ${(() => {
                const dateString = user.rhm_approval_date;
                if (!dateString) return ''; // Return an empty string if no date is provided

                const cleanDate = dateString.split(' ')[0]; // Extract only the date part (before space)

                // Handle DD/MM/YYYY format (no changes needed)
                if (/\d{2}\/\d{2}\/\d{4}/.test(cleanDate)) {
                  return cleanDate; // Return DD/MM/YYYY as-is
                }

                // Handle DD-MM-YY format (no changes needed)
                if (/\d{2}-\d{2}-\d{2}/.test(cleanDate)) {
                  return cleanDate; // Return DD-MM-YY as-is
                }

                // Handle YYYY-MM-DD format or valid ISO date
                const formattedDate = new Date(cleanDate);
                if (formattedDate.toString() !== 'Invalid Date') {
                  return formattedDate.toLocaleDateString('en-IN', {
                    timeZone: 'Asia/Kolkata',
                  });
                }

                return ''; // Return an empty string for unrecognized formats
              })()}</td></tr>
       


              <tr>
                <td  class="no-border"">
                  <img src="https://ooh.warburttons.com/${
                    item.image
                  }" alt="" width="60" height="60" />
                </td>
                <td class="no-border">
                  <img src="https://ooh.warburttons.com/${
                    item.image1
                  }" alt="" width="60" height="60" />
                </td>
             
                <td class="no-border">
                  <img src="https://ooh.warburttons.com/${
                    item.image2
                  }" alt="" width="60" height="60" />
                </td>
                  <td class="no-border">
                  <img src="https://ooh.warburttons.com/${
                    item.image3
                  }" alt="" width="60" height="60" />
                </td>
                  <td class="no-border">
                  <img src="https://ooh.warburttons.com/${
                    item.image4
                  }" alt="" width="60" height="60" />
                </td>

                
              </tr>
             
            </tbody>
          </table>
        </body>
              `,
            )
            .join('')}
            </tbody>
          </table>
        </body>
      </html>
    `);
    printWindow.onload = () => {
      const { images } = printWindow.document; // Use object destructuring here
      const totalImages = images.length;
      let loadedImages = 0;

      const imageLoaded = () => {
        loadedImages++;
        if (loadedImages === totalImages) {
          printWindow.print();
        }
      };

      for (let i = 0; i < totalImages; i++) {
        images[i].onload = imageLoaded;
        images[i].onerror = imageLoaded;
      }

      if (totalImages === 0) {
        printWindow.print();
      }
    };
    printWindow.document.close();
    printWindow.print();
  };

  if (!user) return <div>Loading...</div>;





  const handleApproval = async (status) => {
  


    const userData = localStorage.getItem('user');
    if (!userData) {
      alert('User data not found in localStorage!');
      return;
    }

    const parsedUserData = JSON.parse(userData);
    const nodleAgencyApprovalId = parsedUserData.id || " ";
    console.log(nodleAgencyApprovalId)
    const apiUrl = "https://ooh.warburttons.com/api/nodle-agency-approval";

    const params = {
      project_id: id,
      nodle_agency_approval_id:nodleAgencyApprovalId,
      nodle_agency_status: status,
    };

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      });

      if (response.ok) {
        const data = await response.json();
        alert(`Action successful: ${status}`);
        console.log(data); // Handle the response data as needed
      } else {
        alert('Action failed');
        console.error('Error:', response.statusText);
      }
    } catch (error) {
      alert('An error occurred');
      console.error('Error:', error);
    }
  };
  return (
    <>
      <div style={{ background: '#fff', borderRadius: '10px' }}>
        <div className="p-4">
          <div className="d-flex flex justify-content-between">
            <div>
              <h4 className="d-flex">
                <span>Site Name :</span> {user.site_name}, &nbsp; &nbsp;
                <h4>
                  <span>Code :</span> {user.code}
                </h4>
              </h4>
            </div>
            <div>
              <button type="button" onClick={exportToExcel} className="btn btn-primary mb-3 me-2">
                Export to Excel
              </button>
              <button type="button" onClick={printTable} className="btn btn-secondary mb-3">
                Print Table
              </button>
            </div>
          </div>
          <table className="table ">
            <tbody>
              <tr>
                <td width="200">
                  <h6>Zone </h6>
                </td>
                <td>: {user.zone}</td>

                <td width="200">
                  <h6>City </h6>
                </td>
                <td>: {user.city}</td>

                {/* <td width="200">
                  <h6>Location </h6>
                </td>
                <td width="300">: {user.location}</td> */}
                <td width="200">
                  <h6>Vendor Name </h6>
                </td>
                <td>: {user.vendor_name}</td>
              </tr>

              <tr>
                <td width="200">
                  <h6>State </h6>
                </td>
                <td>: {user.state}</td>

                <td width="200">
                  <h6>Start Date </h6>
                </td>
                <td>: {user.start_date}</td>

                <td width="200">
                  <h6>End Date </h6>
                </td>
                <td>: {user.end_date}</td>
              </tr>

              <tr>
                <td width="200">
                  <h6>Height </h6>
                </td>
                <td>: {user.length}</td>
                <td width="200">
                  <h6>Width </h6>
                </td>
                <td>: {user.width}</td>
                <td width="200">
                  <h6>Total </h6>
                </td>
                <td>: {user.total}</td>
              </tr>

              <tr>
                <td width="200">
                  <h6>Lat </h6>
                </td>
                <td>: {user.lat}</td>
                <td width="200">
                  <h6>Long </h6>
                </td>
                <td>: {user.long}</td>

                <td width="200">
                  <h6>Vendor Status </h6>
                </td>
                <td>
                  : {user.vendor_status}{' '}
                  {(() => {
                    const dateString = user.vendor_approval_date;
                    if (!dateString) return ''; // Return an empty string if no date is provided

                    // Normalize formats and remove time
                    const cleanDate = dateString.split(' ')[0]; // Extract only the date part (before space)

                    // Handle DD/MM/YYYY format (no changes needed)
                    if (/\d{2}\/\d{2}\/\d{4}/.test(cleanDate)) {
                      return cleanDate; // Return DD/MM/YYYY as-is
                    }

                    // Handle DD-MM-YY format (no changes needed)
                    if (/\d{2}-\d{2}-\d{2}/.test(cleanDate)) {
                      return cleanDate; // Return DD-MM-YY as-is
                    }

                    // Handle YYYY-MM-DD format or valid ISO date
                    const formattedDate = new Date(cleanDate);
                    if (formattedDate.toString() !== 'Invalid Date') {
                      return formattedDate.toLocaleDateString('en-IN', {
                        timeZone: 'Asia/Kolkata',
                      });
                    }

                    return ''; // Return an empty string for unrecognized formats
                  })()}
                </td>
              </tr>
              <tr>
                <td width="200">
                  <h6>Nodal Agency Status </h6>
                </td>
                <td>
                  : {user.nodle_agency_status}{' '}
                  {(() => {
                    const dateString = user.nodle_agency_approval_date;
                    if (!dateString) return ''; // Return an empty string if no date is provided

                    // Normalize formats and remove time
                    const cleanDate = dateString.split(' ')[0]; // Extract only the date part (before space)

                    // Handle DD/MM/YYYY format (no changes needed)
                    if (/\d{2}\/\d{2}\/\d{4}/.test(cleanDate)) {
                      return cleanDate; // Return DD/MM/YYYY as-is
                    }

                    // Handle DD-MM-YY format (no changes needed)
                    if (/\d{2}-\d{2}-\d{2}/.test(cleanDate)) {
                      return cleanDate; // Return DD-MM-YY as-is
                    }

                    // Handle YYYY-MM-DD format or valid ISO date
                    const formattedDate = new Date(cleanDate);
                    if (formattedDate.toString() !== 'Invalid Date') {
                      return formattedDate.toLocaleDateString('en-IN', {
                        timeZone: 'Asia/Kolkata',
                      });
                    }

                    return ''; // Return an empty string for unrecognized formats
                  })()}
                </td>
                <td width="200">
                  <h6>State Head Status</h6>
                </td>
                <td>
                  : {user.asm_status}{' '}
                  {(() => {
                    const dateString = user.asm_approval_date;
                    if (!dateString) return ''; // Return an empty string if no date is provided

                    // Normalize formats and remove time
                    const cleanDate = dateString.split(' ')[0]; // Extract only the date part (before space)

                    // Handle DD/MM/YYYY format (no changes needed)
                    if (/\d{2}\/\d{2}\/\d{4}/.test(cleanDate)) {
                      return cleanDate; // Return DD/MM/YYYY as-is
                    }

                    // Handle DD-MM-YY format (no changes needed)
                    if (/\d{2}-\d{2}-\d{2}/.test(cleanDate)) {
                      return cleanDate; // Return DD-MM-YY as-is
                    }

                    // Handle YYYY-MM-DD format or valid ISO date
                    const formattedDate = new Date(cleanDate);
                    if (formattedDate.toString() !== 'Invalid Date') {
                      return formattedDate.toLocaleDateString('en-IN', {
                        timeZone: 'Asia/Kolkata',
                      });
                    }

                    return ''; // Return an empty string for unrecognized formats
                  })()}
                </td>
                <td width="200">
                  <h6>RMH Status </h6>
                </td>
                <td>
                  : {user.client_status}{' '}
                  {(() => {
                    const dateString = user.rhm_approval_date;
                    if (!dateString) return ''; // Return an empty string if no date is provided

                    // Normalize formats and remove time
                    const cleanDate = dateString.split(' ')[0]; // Extract only the date part (before space)

                    // Handle DD/MM/YYYY format (no changes needed)
                    if (/\d{2}\/\d{2}\/\d{4}/.test(cleanDate)) {
                      return cleanDate; // Return DD/MM/YYYY as-is
                    }

                    // Handle DD-MM-YY format (no changes needed)
                    if (/\d{2}-\d{2}-\d{2}/.test(cleanDate)) {
                      return cleanDate; // Return DD-MM-YY as-is
                    }

                    // Handle YYYY-MM-DD format or valid ISO date
                    const formattedDate = new Date(cleanDate);
                    if (formattedDate.toString() !== 'Invalid Date') {
                      return formattedDate.toLocaleDateString('en-IN', {
                        timeZone: 'Asia/Kolkata',
                      });
                    }

                    return ''; // Return an empty string for unrecognized formats
                  })()}
                </td>
              </tr>
              <tr>
                <td width="200">
                  <h6>Recce Person Name </h6>
                </td>
                <td>: {user.racce_person_name}</td>

                <td width="200">
                  <h6>Recce Person Image </h6>
                </td>
                <td>
                  :{' '}
                  <button
                    type="button"
                    onClick={() =>
                      openModal(`https://ooh.warburttons.com/${user.racce_person_image}`)
                    }
                    style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }} // Optional styling
                  >
                    <img
                      src={`https://ooh.warburttons.com/${user.racce_person_image}`}
                      alt="Person"
                      width={60}
                      height={60}
                      onError={(e) => {
                        e.target.style.display = 'none'; // Hide the broken image
                        e.target.insertAdjacentHTML('afterend', '<span>NA</span>'); // Insert "NA" next to the image
                      }}
                    />
                  </button>
                </td>
              </tr>

              <tr>
                <td width="200" className="d-flex gap-2">
                  {user.image ? (
                    <button
                      type="button"
                      style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
                      onClick={() => handleImageClick(0)}
                    >
                      <img
                        src={`https://ooh.warburttons.com/${user.image}`}
                        alt=""
                        width={60}
                        height={60}
                      />
                    </button>
                  ) : (
                    ''
                  )}

                  {user.image1 ? (
                    <button
                      type="button"
                      style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
                      onClick={() => handleImageClick(1)}
                    >
                      <img
                        src={`https://ooh.warburttons.com/${user.image1}`}
                        alt=""
                        width={60}
                        height={60}
                      />
                    </button>
                  ) : (
                    ''
                  )}
                  {user.image2 ? (
                    <button
                      type="button"
                      style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
                      onClick={() => handleImageClick(2)}
                    >
                      <img
                        src={`https://ooh.warburttons.com/${user.image2}`}
                        alt=""
                        width={60}
                        height={60}
                      />
                    </button>
                  ) : (
                    ' '
                  )}
                  {user.image3 ? (
                    <button
                      type="button"
                      style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
                      onClick={() => handleImageClick(3)}
                    >
                      <img
                        src={`https://ooh.warburttons.com/${user.image3}`}
                        alt=""
                        width={60}
                        height={60}
                      />
                    </button>
                  ) : (
                    ''
                  )}

                  {user.image4 ? (
                    <button
                      type="button"
                      style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
                      onClick={() => handleImageClick(4)}
                    >
                      <img
                        src={`https://ooh.warburttons.com/${user.image4}`}
                        alt=""
                        width={60}
                        height={60}
                      />
                    </button>
                  ) : (
                    ''
                  )}
                  <td style={{ minWidth: '230px' }}>Image Updated Date :</td>
                  <td style={{ minWidth: '230px' }}>
                    <td style={{ minWidth: '230px' }}>
                      {(() => {
                        const dateString = user.image_last_updated_date;
                        if (!dateString) return ''; // Return an empty string if no date is provided

                        // Normalize formats and remove time
                        const cleanDate = dateString.split(' ')[0]; // Extract only the date part (before space)

                        // Handle DD/MM/YYYY format (no changes needed)
                        if (/\d{2}\/\d{2}\/\d{4}/.test(cleanDate)) {
                          return cleanDate; // Return DD/MM/YYYY as-is
                        }

                        // Handle DD-MM-YY format (no changes needed)
                        if (/\d{2}-\d{2}-\d{2}/.test(cleanDate)) {
                          return cleanDate; // Return DD-MM-YY as-is
                        }

                        // Handle YYYY-MM-DD format or valid ISO date
                        const formattedDate = new Date(cleanDate);
                        if (formattedDate.toString() !== 'Invalid Date') {
                          return formattedDate.toLocaleDateString('en-IN', {
                            timeZone: 'Asia/Kolkata',
                          });
                        }

                        return ''; // Return an empty string for unrecognized formats
                      })()}
                    </td>
                  </td>
                </td>
              </tr>
           
            </tbody>
            <tr>
              <div className='d-flex gap-2 mt-3' > 
              <button type="button" style={{background:"blue", padding:'8px 20px', border:'none', color:'#fff', borderRadius:'8px'}} onClick={() => handleApproval('Approved')}>Approve</button>
              <button type="button" style={{background:"red", padding:'8px 20px', border:'none', color:'#fff', borderRadius:'8px'}} onClick={() => handleApproval('Rejected')}>Reject</button>
              </div>
              </tr>
          </table>
          <Modal className="custom-modal" show={showCarousel} onHide={handleCloseCarousel}>
            <Modal.Header className="header-m">
              <button type="button" className="cross" onClick={handleCloseCarousel}>
                x
              </button>
            </Modal.Header>
            <Modal.Body>
              <Carousel
                activeIndex={currentImageIndex}
                onSelect={(selectedIndex) => setCurrentImageIndex(selectedIndex)}
              >
                {[user.image, user.image1, user.image2, user.image3, user.image4].map((img) => (
                  <Carousel.Item key={img}>
                    <img className="" src={`https://ooh.warburttons.com/${img}`} alt="Slide" />
                  </Carousel.Item>
                ))}
              </Carousel>
            </Modal.Body>
          </Modal>

          {isModalOpen && (
            <div style={modalStyles.overlay}>
              <div style={modalStyles.modal}>
                <button
                  onClick={closeModal}
                  type="button"
                  className="primary mb-2"
                  style={modalStyles.closeButton}
                >
                  x
                </button>
                <img src={selectedImage} alt="Preview" style={modalStyles.image} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NodalAgencyPending;
