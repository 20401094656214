import React, { useEffect, useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Card, CardText } from 'reactstrap';
import { Link } from 'react-router-dom';

import ComponentCard from '../../components/ComponentCard';

const NodalAgency = () => {
  const [activeTab, setActiveTab] = useState('1');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchTabData = async (tab) => {
    setLoading(true);
    setError(null);
    let api = 'https://ooh.warburttons.com/api/nodle-agency-all-sites'; // Default API (All)

    if (tab === '2') {
      api = 'https://ooh.warburttons.com/api/nodle-agency-approved-sites';
    } else if (tab === '3') {
      api = 'https://ooh.warburttons.com/api/nodle-agency-pending-sites';
    } else if (tab === '4') {
      api = 'https://ooh.warburttons.com/api/nodle-agency-rejected-sites';
    }

    try {
      const response = await fetch(api);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      setData(result.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      fetchTabData(tab);
    }
  };
  useEffect(() => {
    fetchTabData(activeTab);
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <>
      <ComponentCard title="Nodal Agency Projects">
        <Nav tabs>
          <NavItem>
            <NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => toggle('1')}>
              All
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => toggle('2')}>
              Approved
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab === '3' ? 'active' : ''} onClick={() => toggle('3')}>
              Pending
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab === '4' ? 'active' : ''} onClick={() => toggle('4')}>
              Rejected
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent className="p-4" activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              {data.map((item) => (
                <Col md="6" lg="4" key={item.id}>
                  <Card body>
                    <div
                      id={`carousel-${item.id}`}
                      className="carousel slide"
                      data-bs-touch="false"
                    >
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img
                            src={`https://ooh.warburttons.com/${item.image}`}
                            loading="lazy"
                            className="d-block w-100"
                            style={{ height: '200px' }}
                            alt={item.site_name}
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            src={`https://ooh.warburttons.com/${item.image1}`}
                            loading="lazy"
                            className="d-block w-100"
                            style={{ height: '200px' }}
                            alt={item.site_name}
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            src={`https://ooh.warburttons.com/${item.image2}`}
                            loading="lazy"
                            className="d-block w-100"
                            style={{ height: '200px' }}
                            alt={item.site_name}
                          />
                        </div>
                      </div>
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target={`#carousel-${item.id}`}
                        data-bs-slide="prev"
                      >
                        <span className="carousel-control-prev-icon " aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target={`#carousel-${item.id}`}
                        data-bs-slide="next"
                      >
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                    <Link to={`/nodal-agency-detail/${item.id}`} style={{color:'#333', textDecoration: 'none', marginTop:'10px' }}>
                      <CardText>{item.code}</CardText>
                      <CardText>{item.site_name}</CardText>
                    </Link>
                  </Card>
                </Col>
              ))}
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              {data.map((item) => (
                <Col md="6" lg="4" key={item.id}>
                  <Card body>
                    <div
                      id={`carousel-${item.id}`}
                      className="carousel slide"
                      data-bs-touch="false"
                    >
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img
                            src={`https://ooh.warburttons.com/${item.image}`}
                            loading="lazy"
                            className="d-block w-100"
                            style={{ height: '200px' }}
                            alt={item.site_name}
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            src={`https://ooh.warburttons.com/${item.image1}`}
                            loading="lazy"
                            className="d-block w-100"
                            style={{ height: '200px' }}
                            alt={item.site_name}
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            src={`https://ooh.warburttons.com/${item.image2}`}
                            loading="lazy"
                            className="d-block w-100"
                            style={{ height: '200px' }}
                            alt={item.site_name}
                          />
                        </div>
                      </div>
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target={`#carousel-${item.id}`}
                        data-bs-slide="prev"
                      >
                        <span className="carousel-control-prev-icon " aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target={`#carousel-${item.id}`}
                        data-bs-slide="next"
                      >
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                    <Link to={`/nodal-agency-detail/${item.id}`} style={{color:'#333', textDecoration: 'none', marginTop:'10px' }}>
                          <CardText>{item.code}</CardText>
                      <CardText>{item.site_name}</CardText>
                    </Link>
                  </Card>
                </Col>
              ))}
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              {data.map((item) => (
                <Col md="6" lg="4" key={item.id}>
                  <Card body>
                    <div
                      id={`carousel-${item.id}`}
                      className="carousel slide"
                      data-bs-touch="false"
                    >
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img
                            src={`https://ooh.warburttons.com/${item.image}`}
                            loading="lazy"
                            className="d-block w-100"
                            style={{ height: '200px' }}
                            alt={item.site_name}
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            src={`https://ooh.warburttons.com/${item.image1}`}
                            loading="lazy"
                            className="d-block w-100"
                            style={{ height: '200px' }}
                            alt={item.site_name}
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            src={`https://ooh.warburttons.com/${item.image2}`}
                            loading="lazy"
                            className="d-block w-100"
                            style={{ height: '200px' }}
                            alt={item.site_name}
                          />
                        </div>
                      </div>
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target={`#carousel-${item.id}`}
                        data-bs-slide="prev"
                      >
                        <span className="carousel-control-prev-icon " aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target={`#carousel-${item.id}`}
                        data-bs-slide="next"
                      >
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                    <Link to={`/nodal-agency-pending/${item.id}`} style={{color:'#333', textDecoration: 'none', marginTop:'10px' }}>
                      <CardText >{item.code}</CardText>
                      <CardText >{item.site_name}</CardText>
                    </Link>
                  </Card>
                </Col>
              ))}
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              {data.map((item) => (
                <Col md="6" lg="4" key={item.id}>
                  <Card body>
                    <div
                      id={`carousel-${item.id}`}
                      className="carousel slide"
                      data-bs-touch="false"
                    >
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img
                            src={`https://ooh.warburttons.com/${item.image}`}
                            loading="lazy"
                            className="d-block w-100"
                            style={{ height: '200px' }}
                            alt={item.site_name}
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            src={`https://ooh.warburttons.com/${item.image1}`}
                            loading="lazy"
                            className="d-block w-100"
                            style={{ height: '200px' }}
                            alt={item.site_name}
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            src={`https://ooh.warburttons.com/${item.image2}`}
                            loading="lazy"
                            className="d-block w-100"
                            style={{ height: '200px' }}
                            alt={item.site_name}
                          />
                        </div>
                      </div>
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target={`#carousel-${item.id}`}
                        data-bs-slide="prev"
                      >
                        <span className="carousel-control-prev-icon " aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target={`#carousel-${item.id}`}
                        data-bs-slide="next"
                      >
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                    <Link to={`/nodal-agency-detail/${item.id}`} style={{color:'#333', textDecoration: 'none', marginTop:'10px' }}>
                      <CardText>{item.code}</CardText>
                      <CardText>{item.site_name}</CardText>
                    </Link>
                  </Card>
                </Col>
              ))}
            </Row>
          </TabPane>
        </TabContent>
      </ComponentCard>
    </>
  );
};

export default NodalAgency;
